<template>
  <div data-cy="asset-documents">
    <TabTitle class="mb-4" icon="fa-files">
      <template>Documents</template>
      <template #sub-title
        >All documents associated with this asset can be found here. Use the filters on the left to switch between manual uploads, automatically
        retrieved EPC certificates and more.</template
      >
    </TabTitle>
    <div class="content mt-0 pt-0">
      <Spinner v-if="loading" />
      <div v-else class="row">
        <div class="col-xl-3 col-lg-4 col-md-5 col-12">
          <VerticalPills item-key="_id" :items="categories" count-text="accounts" class="mb-3">
            <template #item="{ item }">
              <a
                href="#"
                class="nav-link d-flex align-items-center justify-content-between"
                :class="{ active: item.value === selectedCategoryId }"
                @click.prevent="selectedCategoryId = item.value"
                ><span>{{ item.label }} <i v-if="item.hasLookup" class="fa fa-bolt text-warning ml-1"></i></span>

                <div class="badge badge-pill text-capitalize badge-info">{{ item.count }}</div>
              </a>
            </template>
          </VerticalPills>
          <div v-if="$permissions.write('asset', asset)">
            <div v-if="fileUpload.error" class="alert alert-danger"><i class="fa fa-spin fa-circle-exclamation"></i> {{ fileUpload.error }}</div>
            <a
              href="#"
              class="btn btn-secondary btn-block"
              :class="{ disabled: !selectedCategory.allowUpload || (fileUpload.loading && !fileUpload.error) }"
              @click.prevent="$refs.fileUpload.click()"
            >
              <span v-if="fileUpload.loading && !fileUpload.error"
                ><i class="fa fa-spin fa-circle-notch mr-1"></i> Uploading...{{ documentUpload.progress | numberFormat(2) }}%</span
              >
              <span v-else>Upload Document</span>
            </a>
            <input ref="fileUpload" class="d-none" type="file" name="document" multiple @input="onFileSelect" />
          </div>
        </div>
        <div class="col-xl-9 col-lg-8 col-md-7 col-12">
          <div v-if="selectedCategory.hasLookup && $permissions.write('asset', asset)" class="bg-light p-3 mb-4">
            <div class="row align-items-center">
              <div class="col mb-md-0 mb-3">
                <h5 class="mb-2"><i class="fa fa-bolt text-warning mr-1"></i> Smart Lookup</h5>
                <p>
                  This category uses <strong>Smart Lookup</strong> to try and locate documents for this Asset. Use the lookup button on the right to
                  start a search. If you see any results relevant to this asset, click the
                  <span class="font-w600"><i class="fa fa-plus"></i> ADD</span> button to add the document to this Asset.
                </p>
                <p class="mb-0">
                  <strong>Please note</strong> not all results may be relevant to this Asset, some may be for nearby buildings, units or businesses.
                </p>
              </div>
              <div class="col-md-2 col-lg-3">
                <button class="btn btn-primary btn-block" @click="modals.lookup = !modals.lookup"><i class="fa fa-search mr-1"></i> Lookup</button>
              </div>
            </div>
          </div>
          <p v-if="selectedCategory.description">
            {{ selectedCategory.description }}
            <a class="font-w600 text-primary" :href="selectedCategory.website">Click here</a> to find out more.
          </p>
          <div class="row">
            <div class="col">
              <input v-model="filters.search" class="form-control" placeholder="Search documents..." />
            </div>
            <div class="col-md-6">
              <Pagination
                class="mb-3"
                summary
                :current-page="filters.page"
                :total-pages="Math.ceil(filteredDocuments.length / filters.perPage)"
                @change="p => (filters.page = p)"
              />
            </div>
          </div>
          <div v-if="pagedDocuments.length > 0" id="accordion" role="tablist">
            <div v-for="doc in pagedDocuments" :key="doc._id" class="block block-rounded mb-3">
              <div class="block-header block-header-default d-flex" role="tab">
                <div class="flex-grow-1">
                  <div class="d-flex justify-content-between align-items-center">
                    <FileIcon class="fal fa-fw fa-2x mr-3" :extension="doc.extension || doc.type" />

                    <div v-if="renameDocument[doc._id]" class="flex-grow-1 d-flex mr-4">
                      <input
                        v-model="renameDocument[doc._id]"
                        class="form-control d-inline-block"
                        :disabled="loadingAction.updateDocument === doc._id"
                      />
                      <span v-if="loadingAction.updateDocument === doc._id">
                        <button class="btn btn-link">
                          <i class="fa fa-lg fa-spin fa-circle-notch"></i>
                        </button>
                      </span>
                      <span v-else class="d-flex">
                        <button class="btn btn-link text-success" @click.prevent="onClickSaveDocument(doc)">
                          <i class="fa fa-lg fa-check"></i>
                        </button>
                        <button class="btn btn-link text-danger" @click.prevent="onClickCancelDocument(doc)">
                          <i class="fa fa-lg fa-times"></i>
                        </button>
                      </span>
                    </div>
                    <div v-else class="flex-grow-1">
                      <a href="#" @click.prevent="onClickDocument(doc)">
                        <div>
                          <strong>{{ doc.name }}</strong>
                        </div>
                        <div>
                          <small v-if="doc.score" class="font-w600 text-muted">Score: {{ doc.score }} <span v-if="doc.date">| </span> </small>
                          <small v-if="doc.date" class="font-w600 text-muted">Uploaded on {{ doc.date | date('Do MMM YYYY HH:mm') }}</small>
                        </div></a
                      >
                    </div>
                  </div>
                </div>
                <div v-if="$permissions.write('asset', asset)">
                  <button v-if="!renameDocument[doc._id]" class="btn btn-link font-w600 text-secondary" @click="onClickRename(doc)">
                    <i class="fa fa-fw fa-pencil"></i> Rename
                  </button>
                  <div class="dropdown d-inline">
                    <button
                      v-if="categories.filter(c => c.allowUpload).length > 1"
                      class="btn btn-link font-w600 text-secondary"
                      @click="showCategories = showCategories === doc._id ? null : doc._id"
                    >
                      <i class="fa fa-fw fa-tags"></i> Category
                    </button>
                    <div v-if="showCategories === doc._id" class="dropdown-menu show">
                      <a
                        v-for="category in categories.filter(c => c.allowUpload && c.value !== selectedCategoryId)"
                        :key="category.value"
                        class="dropdown-item"
                        href="#"
                        @click.prevent="onClickChangeType(doc, category.value)"
                        >{{ category.label }}</a
                      >
                    </div>
                  </div>
                  <button class="btn btn-link font-w600 text-danger" @click="onClickDelete(doc)"><i class="fa fa-fw fa-trash"></i> Delete</button>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="alert alert-warning">No documents found with the selected filters.</div>
          </div>
          <Pagination
            class="mb-3"
            summary
            :current-page="filters.page"
            :total-pages="Math.ceil(filteredDocuments.length / filters.perPage)"
            @change="p => (filters.page = p)"
          />
        </div>
      </div>
    </div>
    <ConfirmModal
      :open="!!modals.remove"
      title="Delete Certificate"
      :text="`Please confirm you would like to remove document: <strong>${modals.remove.name}</strong>`"
      @close="modals.remove = false"
      @submit="onRemoveDocument"
    />
    <ConfirmModal id="lookup-certificates" :open="!!modals.lookup" title="Lookup Certificates" prevent lg-size hide-submit @close="onCloseLookup">
      <CertificateLookup v-if="!!modals.lookup" :asset="asset" :category="selectedCategoryId" />
    </ConfirmModal>
  </div>
</template>
<script>
import bluebird from 'bluebird';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { capitalize } from '@/lib/helpers';

import CertificateLookup from '@/components/CertificateLookup';
import ConfirmModal from '@/components/ConfirmModal';
import Pagination from '@/components/Pagination';
import FileIcon from '@/components/FileIcon';
import Spinner from '@/components/Spinner';
import TabTitle from '@/components/base/TabTitle';
import VerticalPills from '@/components/base/VerticalPills';

export default {
  name: 'AssetDocumentsList',
  components: {
    ConfirmModal,
    CertificateLookup,
    FileIcon,
    Pagination,
    Spinner,
    TabTitle,
    VerticalPills
  },
  data() {
    return {
      modals: {
        remove: false,
        lookup: false,
        edit: false
      },
      loading: true,
      form: {
        type: 'upload'
      },
      file: null,
      showCreate: false,
      documents: [],
      view: '',
      tabs: ['active', 'pending', 'rejected'],
      selectedCategoryId: 'upload',
      renameDocument: {},
      showCategories: null,
      fileUpload: {},
      sortBy: 'date',
      filters: {
        page: 1,
        perPage: 20,
        sortBy: 'date',
        sortOrder: 'desc',
        search: ''
      }
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      documentUpload: 'asset/documentUpload',
      loadingAction: 'asset/loadingAction',
      errorAction: 'asset/errorAction',
      validationErrors: 'asset/validationErrors',
      accounts: 'asset/accounts',
      company: 'user/company'
    }),
    selectedCategory() {
      return this.categories.find(c => c.value === this.selectedCategoryId);
    },
    filteredDocuments() {
      const documents = this.asset.documents
        .filter(doc => doc.type === this.selectedCategoryId)
        .filter(doc => !this.filters.search || doc.name.toLowerCase().includes(this.filters.search.toLowerCase()));

      documents.sort((a, b) => new Date(b.date) - new Date(a.date));

      return documents;
    },
    pagedDocuments() {
      return this.filteredDocuments.slice((this.filters.page - 1) * this.filters.perPage, this.filters.page * this.filters.perPage);
    },
    categories() {
      const companyCategories =
        this.company.settings && this.company.settings.documentCategories
          ? this.company.settings.documentCategories.map(category => ({
              label: category,
              value: category,
              allowUpload: true,
              count: (this.asset.documents || []).filter(doc => doc.type === category).length
            }))
          : [];

      const categories = [
        {
          label: 'Uploads',
          value: 'upload',
          allowUpload: true,
          count: (this.asset.documents || []).filter(doc => doc.type === 'upload').length
        },
        {
          label: 'EPC/DEC/AC Certificates',
          value: 'epb',
          description: 'EPC, DEC and AC certificates are used to determine the energy performance of buildings',
          website: 'https://www.gov.uk/energy-performance-certificate-commercial-property',
          hasLookup: true,
          count: (this.asset.documents || []).filter(doc => doc.type === 'epb').length
        },
        {
          label: 'BREEAM Certificates',
          value: 'breeam',
          website: 'https://bregroup.com/products/breeam/',
          description:
            'A BREEAM certified rating reflects the performance achieved by a project and its stakeholders, as measured against the BREEAM standard and its benchmarks.',
          hasLookup: true,
          count: (this.asset.documents || []).filter(doc => doc.type === 'breeam').length
        },
        {
          label: 'WiredScore Certificates',
          value: 'wiredScore',
          website: 'https://wiredscore.com/',
          description:
            'WiredScore assesses, certifies and improves digital connectivity and smart technology in homes and offices on a global scale.',
          hasLookup: true,
          count: (this.asset.documents || []).filter(doc => doc.type === 'wiredScore').length
        },
        ...companyCategories
      ];

      categories.sort((a, b) => a.label.localeCompare(b.label));

      return categories;
    },

    accountOptions() {
      const options = this.accounts.map(acc => ({ label: `${capitalize(acc.type)} - ${acc.name} [${acc.meterPointNumber}]`, value: acc._id }));
      options.sort((a, b) => a.label.localeCompare(b.label));
      return options;
    }
  },
  async mounted() {
    await this.getAccounts({ id: this.$route.params.id });
    this.refresh();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      createDocument: 'asset/createDocument',
      deleteDocument: 'asset/deleteDocument',
      updateDocument: 'asset/updateDocument',
      uploadDocuments: 'asset/uploadDocuments',
      downloadDocument: 'asset/downloadDocument',
      addCertificate: 'asset/addCertificate',
      update: 'asset/update',
      getAccounts: 'asset/accounts'
    }),
    ...mapMutations({
      clearSearchedCerts: 'asset/CLEAR_SEARCHED_CERTS',
      setValidationErrors: 'asset/SET_VALIDATION_ERRORS'
    }),
    onClickRename(doc) {
      this.$set(this.renameDocument, doc._id, doc.name);
    },
    async onClickDocument(doc) {
      try {
        const link = document.createElement('a');

        link.target = '_blank';

        if (doc.certificate?.url) {
          link.href = doc.certificate.url;
        } else if (doc.certificate?.type === 'epc') {
          this.$router.push(doc.certificate['building-reference-number']);
        } else {
          const downloadUrl = await this.downloadDocument({
            id: this.asset._id,
            documentId: doc._id,
            name: doc.name
          });

          link.href = downloadUrl;
          link.download = doc.name;
        }

        link.click();
        URL.revokeObjectURL(link.href);
      } catch (e) {
        this.$toasted.error(e.errorMsg || 'Could not open document', { position: 'bottom-center', duration: 3000 });
      }
    },
    async onClickSaveDocument(doc) {
      await this.updateDocument({
        id: this.asset._id,
        documentId: doc._id,
        data: {
          name: this.renameDocument[doc._id]
        }
      });

      this.$set(this.renameDocument, doc._id, null);
    },
    async onClickChangeType(doc, type) {
      this.showCategories = null;

      await this.updateDocument({
        id: this.asset._id,
        documentId: doc._id,
        data: {
          type
        }
      });

      this.$toasted.success('Document category updated');
    },
    onClickCancelDocument(doc) {
      this.$set(this.renameDocument, doc._id, null);
    },
    onClickDelete(doc) {
      this.modals.remove = doc;
    },
    async onRemoveDocument() {
      try {
        const deleteDocument = await this.deleteDocument({ id: this.asset._id, documentId: this.modals.remove._id });

        if (deleteDocument) {
          this.$toasted.success('Deleted successfully.');
        }
      } catch (e) {
        this.$toasted.error('Could not delete.');
      }

      this.refresh();
      this.modals.remove = false;
    },
    epcBandColour(value) {
      const epcTest = /[0-9]{1,3}([A-G]{1})/.exec(value);
      // const breeamTest = /([0-9]{2}[.]{1}[0-9]{1})%)/.exec(value);
      // console.log('breeamTest', breeamTest);

      if (epcTest) {
        return `band-${epcTest[1]}`;
      } else {
        return 'text-secondary';
      }
    },

    onCloseLookup() {
      this.modals.lookup = false;
      this.clearSearchedCerts();
    },
    refresh() {
      this.view = 'active';
      this.form = { name: '', type: 'upload', accountids: [] };
    },
    async onSubmitEdit() {
      const document = this.asset.documents.find(d => d._id === this.modals.edit._id);

      document.name = this.modals.edit.name;
      document.accountIds = this.modals.edit.accountIds;

      try {
        await this.update({ id: this.$route.params.id, data: { ...this.asset, documents: this.asset.documents } });
      } catch (e) {
        return this.$toasted.error(e.errorMsg || 'Could not update certificates', { position: 'bottom-center', duration: 3000 });
      }
      this.modals.edit = false;
      this.refresh();
    },
    async onMoveCert(cert, newStatus) {
      this.asset.documents.find(c => c._id === cert._id).status = newStatus;

      await this.update({ id: this.$route.params.id, data: { documents: this.asset.documents } });
    },
    async onFileSelect(e) {
      const { name, files } = e.target;

      const formData = new FormData();

      Array.from(files).forEach(file => formData.append(name, file, file.name));

      this.fileUpload = {
        loading: true
      };

      let uploads;
      try {
        uploads = await this.uploadDocuments({ id: this.asset._id, data: formData });
      } catch (e) {
        this.fileUpload.error = 'Could not upload documents;';
        return false;
      }

      if (!uploads || this.errorAction.uploadDocument) {
        this.fileUpload.error = this.errorAction.uploadDocument.message || 'Could not upload documents';
        return false;
      }

      try {
        bluebird.map(
          uploads,
          async file => {
            const parts = file.name.split('.');
            const extension = parts.pop();

            const fileName = parts.join('.');

            await this.createDocument({
              id: this.asset._id,
              data: {
                name: fileName,
                extension,
                s3Key: file.s3Key,
                type: this.selectedCategoryId
              }
            });
          },
          {
            concurrency: 5
          }
        );
      } catch (e) {
        this.fileUpload.error = this.errorAction.createDocument.message || 'Could not create document';
        return false;
      }

      this.$toasted.success('Uploaded successfully.');
      this.fileUpload = {};
    }
  }
};
</script>
<style scoped>
.band-A {
  background-color: #008054;
}
.band-B {
  background-color: #19b459;
}
.band-C {
  background-color: #8dce46;
}
.band-D {
  background-color: #ffd500;
}
.band-E {
  background-color: #fcaa65;
}
.band-F {
  background-color: #ef8023;
}
.band-G {
  background-color: #e9153b;
}
</style>
