var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "asset-documents"
    }
  }, [_c('TabTitle', {
    staticClass: "mb-4",
    attrs: {
      "icon": "fa-files"
    },
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function () {
        return [_vm._v("All documents associated with this asset can be found here. Use the filters on the left to switch between manual uploads, automatically retrieved EPC certificates and more.")];
      },
      proxy: true
    }])
  }, [[_vm._v("Documents")]], 2), _c('div', {
    staticClass: "content mt-0 pt-0"
  }, [_vm.loading ? _c('Spinner') : _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-3 col-lg-4 col-md-5 col-12"
  }, [_c('VerticalPills', {
    staticClass: "mb-3",
    attrs: {
      "item-key": "_id",
      "items": _vm.categories,
      "count-text": "accounts"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('a', {
          staticClass: "nav-link d-flex align-items-center justify-content-between",
          class: {
            active: item.value === _vm.selectedCategoryId
          },
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              _vm.selectedCategoryId = item.value;
            }
          }
        }, [_c('span', [_vm._v(_vm._s(item.label) + " "), item.hasLookup ? _c('i', {
          staticClass: "fa fa-bolt text-warning ml-1"
        }) : _vm._e()]), _c('div', {
          staticClass: "badge badge-pill text-capitalize badge-info"
        }, [_vm._v(_vm._s(item.count))])])];
      }
    }])
  }), _vm.$permissions.write('asset', _vm.asset) ? _c('div', [_vm.fileUpload.error ? _c('div', {
    staticClass: "alert alert-danger"
  }, [_c('i', {
    staticClass: "fa fa-spin fa-circle-exclamation"
  }), _vm._v(" " + _vm._s(_vm.fileUpload.error))]) : _vm._e(), _c('a', {
    staticClass: "btn btn-secondary btn-block",
    class: {
      disabled: !_vm.selectedCategory.allowUpload || _vm.fileUpload.loading && !_vm.fileUpload.error
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$refs.fileUpload.click();
      }
    }
  }, [_vm.fileUpload.loading && !_vm.fileUpload.error ? _c('span', [_c('i', {
    staticClass: "fa fa-spin fa-circle-notch mr-1"
  }), _vm._v(" Uploading..." + _vm._s(_vm._f("numberFormat")(_vm.documentUpload.progress, 2)) + "%")]) : _c('span', [_vm._v("Upload Document")])]), _c('input', {
    ref: "fileUpload",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "name": "document",
      "multiple": ""
    },
    on: {
      "input": _vm.onFileSelect
    }
  })]) : _vm._e()], 1), _c('div', {
    staticClass: "col-xl-9 col-lg-8 col-md-7 col-12"
  }, [_vm.selectedCategory.hasLookup && _vm.$permissions.write('asset', _vm.asset) ? _c('div', {
    staticClass: "bg-light p-3 mb-4"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col mb-md-0 mb-3"
  }, [_c('h5', {
    staticClass: "mb-2"
  }, [_c('i', {
    staticClass: "fa fa-bolt text-warning mr-1"
  }), _vm._v(" Smart Lookup")]), _c('p', [_vm._v(" This category uses "), _c('strong', [_vm._v("Smart Lookup")]), _vm._v(" to try and locate documents for this Asset. Use the lookup button on the right to start a search. If you see any results relevant to this asset, click the "), _c('span', {
    staticClass: "font-w600"
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" ADD")]), _vm._v(" button to add the document to this Asset. ")]), _c('p', {
    staticClass: "mb-0"
  }, [_c('strong', [_vm._v("Please note")]), _vm._v(" not all results may be relevant to this Asset, some may be for nearby buildings, units or businesses. ")])]), _c('div', {
    staticClass: "col-md-2 col-lg-3"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-block",
    on: {
      "click": function ($event) {
        _vm.modals.lookup = !_vm.modals.lookup;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-search mr-1"
  }), _vm._v(" Lookup")])])])]) : _vm._e(), _vm.selectedCategory.description ? _c('p', [_vm._v(" " + _vm._s(_vm.selectedCategory.description) + " "), _c('a', {
    staticClass: "font-w600 text-primary",
    attrs: {
      "href": _vm.selectedCategory.website
    }
  }, [_vm._v("Click here")]), _vm._v(" to find out more. ")]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.search,
      expression: "filters.search"
    }],
    staticClass: "form-control",
    attrs: {
      "placeholder": "Search documents..."
    },
    domProps: {
      "value": _vm.filters.search
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filters, "search", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('Pagination', {
    staticClass: "mb-3",
    attrs: {
      "summary": "",
      "current-page": _vm.filters.page,
      "total-pages": Math.ceil(_vm.filteredDocuments.length / _vm.filters.perPage)
    },
    on: {
      "change": function (p) {
        return _vm.filters.page = p;
      }
    }
  })], 1)]), _vm.pagedDocuments.length > 0 ? _c('div', {
    attrs: {
      "id": "accordion",
      "role": "tablist"
    }
  }, _vm._l(_vm.pagedDocuments, function (doc) {
    return _c('div', {
      key: doc._id,
      staticClass: "block block-rounded mb-3"
    }, [_c('div', {
      staticClass: "block-header block-header-default d-flex",
      attrs: {
        "role": "tab"
      }
    }, [_c('div', {
      staticClass: "flex-grow-1"
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('FileIcon', {
      staticClass: "fal fa-fw fa-2x mr-3",
      attrs: {
        "extension": doc.extension || doc.type
      }
    }), _vm.renameDocument[doc._id] ? _c('div', {
      staticClass: "flex-grow-1 d-flex mr-4"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.renameDocument[doc._id],
        expression: "renameDocument[doc._id]"
      }],
      staticClass: "form-control d-inline-block",
      attrs: {
        "disabled": _vm.loadingAction.updateDocument === doc._id
      },
      domProps: {
        "value": _vm.renameDocument[doc._id]
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) return;
          _vm.$set(_vm.renameDocument, doc._id, $event.target.value);
        }
      }
    }), _vm.loadingAction.updateDocument === doc._id ? _c('span', [_c('button', {
      staticClass: "btn btn-link"
    }, [_c('i', {
      staticClass: "fa fa-lg fa-spin fa-circle-notch"
    })])]) : _c('span', {
      staticClass: "d-flex"
    }, [_c('button', {
      staticClass: "btn btn-link text-success",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickSaveDocument(doc);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-lg fa-check"
    })]), _c('button', {
      staticClass: "btn btn-link text-danger",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickCancelDocument(doc);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-lg fa-times"
    })])])]) : _c('div', {
      staticClass: "flex-grow-1"
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickDocument(doc);
        }
      }
    }, [_c('div', [_c('strong', [_vm._v(_vm._s(doc.name))])]), _c('div', [doc.score ? _c('small', {
      staticClass: "font-w600 text-muted"
    }, [_vm._v("Score: " + _vm._s(doc.score) + " "), doc.date ? _c('span', [_vm._v("| ")]) : _vm._e()]) : _vm._e(), doc.date ? _c('small', {
      staticClass: "font-w600 text-muted"
    }, [_vm._v("Uploaded on " + _vm._s(_vm._f("date")(doc.date, 'Do MMM YYYY HH:mm')))]) : _vm._e()])])])], 1)]), _vm.$permissions.write('asset', _vm.asset) ? _c('div', [!_vm.renameDocument[doc._id] ? _c('button', {
      staticClass: "btn btn-link font-w600 text-secondary",
      on: {
        "click": function ($event) {
          return _vm.onClickRename(doc);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-pencil"
    }), _vm._v(" Rename ")]) : _vm._e(), _c('div', {
      staticClass: "dropdown d-inline"
    }, [_vm.categories.filter(function (c) {
      return c.allowUpload;
    }).length > 1 ? _c('button', {
      staticClass: "btn btn-link font-w600 text-secondary",
      on: {
        "click": function ($event) {
          _vm.showCategories = _vm.showCategories === doc._id ? null : doc._id;
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-tags"
    }), _vm._v(" Category ")]) : _vm._e(), _vm.showCategories === doc._id ? _c('div', {
      staticClass: "dropdown-menu show"
    }, _vm._l(_vm.categories.filter(function (c) {
      return c.allowUpload && c.value !== _vm.selectedCategoryId;
    }), function (category) {
      return _c('a', {
        key: category.value,
        staticClass: "dropdown-item",
        attrs: {
          "href": "#"
        },
        on: {
          "click": function ($event) {
            $event.preventDefault();
            return _vm.onClickChangeType(doc, category.value);
          }
        }
      }, [_vm._v(_vm._s(category.label))]);
    }), 0) : _vm._e()]), _c('button', {
      staticClass: "btn btn-link font-w600 text-danger",
      on: {
        "click": function ($event) {
          return _vm.onClickDelete(doc);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-trash"
    }), _vm._v(" Delete")])]) : _vm._e()])]);
  }), 0) : _c('div', [_c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v("No documents found with the selected filters.")])]), _c('Pagination', {
    staticClass: "mb-3",
    attrs: {
      "summary": "",
      "current-page": _vm.filters.page,
      "total-pages": Math.ceil(_vm.filteredDocuments.length / _vm.filters.perPage)
    },
    on: {
      "change": function (p) {
        return _vm.filters.page = p;
      }
    }
  })], 1)])], 1), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.remove,
      "title": "Delete Certificate",
      "text": `Please confirm you would like to remove document: <strong>${_vm.modals.remove.name}</strong>`
    },
    on: {
      "close": function ($event) {
        _vm.modals.remove = false;
      },
      "submit": _vm.onRemoveDocument
    }
  }), _c('ConfirmModal', {
    attrs: {
      "id": "lookup-certificates",
      "open": !!_vm.modals.lookup,
      "title": "Lookup Certificates",
      "prevent": "",
      "lg-size": "",
      "hide-submit": ""
    },
    on: {
      "close": _vm.onCloseLookup
    }
  }, [!!_vm.modals.lookup ? _c('CertificateLookup', {
    attrs: {
      "asset": _vm.asset,
      "category": _vm.selectedCategoryId
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }